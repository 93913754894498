<template>
<div :class="'pray dirr ' + $store.state.direction">
    <div class="prayer_timesCON">
        <div class="s_wrap them_overlay">
            <div class="container">
                <div class="s_row">
                    <div class="panel panel-default">
                        <div class="panel-heading">
                            <ul class=" nav-tabs iitem">
                                <li>
                                    <a class="left_bottom hvr-ripple-out">
                                        <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                    </a></li>
                                <li>
                                    <a class="left_center hvr-ripple-out">
                                       <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                        <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                        <h6 class="title- tl">{{prayer.rise}}</h6>
                                    </a>
                                </li>
                                <li><a class="center hvr-ripple-out">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                        <h6 class="title- tl">{{prayer.noon}}</h6>
                                    </a></li>
                                <li><a class="right_center hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                        <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                    </a></li>
                                <li><a class="right_bottom hvr-ripple-out">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                        <h6 class="title- tl">{{prayer.mid}}</h6>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="panel-body">
                            <div class="tab-content">

                                <div class="tab-pane">
                                    <div class="iitem-text">
                                        <div class="hands">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/hands-praying.svg#hands-praying"></use></svg>
                                        </div>
                                        <h4 class="titl-2 tl">{{ $t("prayer_times") }}</h4>
                                        <p>{{ $t("prayers_in_karbala") }}</p>
                                        <p class="messiri_fonted prayer_date" >
                                            <span class="hidden-sm-and-down" v-text="dateD+' - '"></span>
                                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                                            <span class="hidden-sm-and-down" style="padding-right:5px" v-text="dateM"></span>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="allcontent">
                    <div class=" heade">
                        <h4 class="titl-2 mb-2 tl">{{ $t("prayer_times") }}</h4>
                        <p class="text-dark">{{ $t("prayers_in_karbala") }}</p>
                        <p class="messiri_fonted text-dark" >
                            <span class="hidden-sm-and-down" v-text="dateD+' -'"></span>
                            <span class="hidden-sm-and-down" v-html="dateH"></span> 
                            <span class="hidden-md-and-up" v-text="dateH_ico"></span>
                            <span class="hidden-sm-and-down" v-text="dateM"> </span>
                        </p>
                    </div>

                    <div>
                        <ul class="ul-item">
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/fajer.svg#fajer"></use></svg>
                                        </div>
                                        <h6 class="title- tl">{{ $t("index_fajir") }}</h6>
                                        <h6 class="title- tl">{{prayer.fajer}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/shoroq.svg#shoroq"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_sunrise") }}</h6>
                                    <h6 class="title- tl">{{prayer.rise}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/sun.svg#sun"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_doher") }}</h6>
                                    <h6 class="title- tl">{{prayer.noon}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui">
                                    <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/ghorp.svg#ghorp"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("index_maghrib") }}</h6>
                                    <h6 class="title- tl">{{prayer.ghrob}}</h6>
                                </div>
                            </li>
                            <li>
                                <div class="block2 cui block3 mb-3">
                                   <div class="serv">
                                            <svg viewBox="0 0 25 25"><use xlink:href="/assets/img/pray_icons/mid-n.svg#mid-n"></use></svg>
                                        </div>

                                    <h6 class="title- tl">{{ $t("midnight") }}</h6>
                                    <h6 class="title- tl">{{prayer.mid}}</h6>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template> -->

<!-- <template>
    <div class="container pray-m my-15">
        <div class="d-flex title-m">
            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
            <span class="tl">اوقات الصلاة</span>
        </div>
        <div class="discrption-m tl my-3">
                مدينة كربلاء المقدسة وضواحيها
        </div>
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <div class="details">
                    <div>
                        <h2 class="my-5">حان الان موعد صلاة  العشائين <br> في كربلاء المقدسة</h2>
                    </div>
                    <div class="note my-5">
                        <span class="small">ملاحظة ..</span>
                    </div>
                    <div class="d-flex">
                        <img src='/assets/img/separator.svg' alt="" class="mx-5">
                        <span class="gray">في مدينة كربلاء المقدسة وضواحيها <br>
                            الاثنين -١٩ ربيع الأول ١٤٤٦ - أيلول ٢٠٢٤</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="main-button mt-4">
                    <div class="d-flex">
                        <div class="m-box d-flex">
                            <img src="assets/img/pray_icons/shoroq-1.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    شروق الشمس
                                </div>
                                <div class="m-time tl">
                                    5:12
                                </div>
                            </div>
                        </div>
                        <div class="m-box d-flex">
                            <img src="/assets/img/pray_icons/noon.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    صلاة الصبح
                                </div>
                                <div class="m-time tl">
                                    7:01
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="m-box d-flex">
                            <img src="/assets/img/pray_icons/noon.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                   صلاة الظهرين
                                </div>
                                <div class="m-time tl">
                                    12:33
                                </div>
                            </div>
                        </div>
                        <div class="m-box d-flex">
                            <img src="/assets/img/pray_icons/ghrop-1.svg" alt="" class="border-m">
                            <div class="date-m px-4">
                                <div class="m-name tl">
                                    صلاة العشائين
                                </div>
                                <div class="m-time tl">
                                    6:10
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- <template>
    <div class="container-fluid">
        <div class="pray-dep">
        <div class="d-flex">
            <div class="small-box">
                <div class="content">
                    <div class="icon-img">
                        <img src="assets/img/pray_icons/shoroq-1.svg" alt="" class="p-icon">
                    </div>
                    <div class="time-d">
                        <p class="pray-name">صلاة الفجر</p>
                        <span class="time-c">
                            4:55
                        </span>
                        <p class="last-time">
                            المتبقي 18 ساعة
                        </p>
                    </div>
                </div>
            </div>
            <div class="small-box">
                <div class="content">
                    <div class="icon-img">
                        <img src="assets/img/pray_icons/shoroq-1.svg" alt="" class="p-icon">
                    </div>
                    <div class="time-d">
                        <p class="pray-name">الشروق</p>
                        <span class="time-c">
                            2:05
                        </span>
                        <p class="last-time">
                            المتبقي 33 دقيقة
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="big-box">
            <div class="content d-flex">
                    <div class="icon-img border-m">
                        <img src="assets/img/pray_icons/n-sun.svg" alt="" class="p-icon">
                    </div>
                    <div class="time-a">
                        <p class="pray-name">صلاة الظهرين</p>
                        <div class="time-c my-1">
                            12:10
                        </div>
                        <p class="last-time">
                            المتبقي 33 دقيقة
                        </p>
                    </div>
                </div>
        </div>
        <div class="d-flex">
            <div class="small-box">
                <div class="content">
                    <div class="icon-img">
                        <img src="assets/img/pray_icons/ghrop-1.svg" alt="" class="p-icon">
                    </div>
                    <div class="time-d">
                        <p class="pray-name">صلاة العشائين</p>
                        <span class="time-c">
                            6:34
                        </span>
                        <p class="last-time">
                            المتبقي 5 ساعات
                        </p>
                    </div>
                </div>
            </div>
            <div class="small-box">
                <div class="content">
                    <div class="icon-img">
                        <img src="assets/img/pray_icons/mid.svg" alt="" class="p-icon">
                    </div>
                    <div class="time-d">
                        <p class="pray-name">منتصف الليل</p>
                        <span class="time-c">
                            4:55
                        </span>
                        <p class="last-time">
                            المتبقي 11 ساعة
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template> -->
<script>

let endpoints = [
    'https://alkafeel.net/main_bg/data/date_main.json?t='+ Date.now(),
    'salaDate'
]
// import Axios from "axios";
export default {
    name: "prayerTimes",
    data() {
        return {
            dateD: '',
            dateH: '',
            dateM: '',
            dateH_ico: '',
            prayer: {"fajer": "00:00","rise": "00:00","noon": "00:00","ghrob": "00:00","mid": "00:00"},
            date: [],
        }
    },
    watch: {
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    mounted() {
    },
    created () {
        this.ini ();
        // this.loadDate ();
        // this.getTimes ();
    },
    methods:{
        // getTimes () {
        //     Axios.get("salaDate", {})
        //     .then(res => {
        //         this.times = res.data;
        //         //console.log(res.data);
        //     })
        //     .catch(() => {
        //     });
        // },
        ini () {
            this.$axios
                .all(endpoints.map((endpoint) => this.$axios.get(endpoint)))
                .then(
                    this.$axios.spread((...responses) => {
                    const responseDate  = responses[0].data;
                    const responsePraym = responses[1].data;

                    if (typeof(responsePraym[0]) !== 'undefined') {this.prayer = responsePraym[0]}
                    this.date = responseDate.langs;
                    
                    try {
                        this.dateD = this.date[this.$i18n.locale].D;
                        this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                        this.dateH_ico = this.date[this.$i18n.locale].H;
                        this.dateM = this.date[this.$i18n.locale].M;
                    } catch (e) {

                    }
                    
                    })
                    
                )
        },
        
        loadDate () {
            try {
                this.dateD = this.date[this.$i18n.locale].D;
                this.dateH = this.date[this.$i18n.locale].H !== '' ? this.date[this.$i18n.locale].H+' <img src="assets/img/z-ali.svg"> ' : '';
                this.dateH_ico = this.date[this.$i18n.locale].H;
                this.dateM = this.date[this.$i18n.locale].M;
            } catch (e) {

            }
        },
    },
}
</script>

<style scoped>
    .prayer_date * {
        font-size: 16px!important;
    }

    /* .title-m {
        justify-content: center;
    }

    .title-m  span{
        font-size: 24px;
    }

    .title-m  span img{
        height: 16px;
        margin: 0 8px;
    }

    .discrption-m {
        font-size: 18px;
        justify-content: center;
        display: flex;
        margin: 12px 0px;
    }

    .small {
        font-size: 12px;
        color: #858585;
    }

    .pray-m {
        max-width: 1237px;
    }

    .gray {
        font-size: 14px;
        color: #858585;
    }

    .m-box {
        width: 250px;
        height: 80px;
        background: #F5F5F5;
        align-items: center;
        border-radius: 4px;
        margin:12px;
    }

    .border-m {
       border-left: 2px solid #D9D9D9;
       height: 34px;
       padding: 0 16px;
    }

    .m-name tl {
        color: #474747;
        font-weight: bold;
        font-size: 14px;
    }

    .m-time tl {
        color: #646464;
        font-weight: bold;
        font-size: 14px;
    } */

    .p-icon {
       height: 64px;
       padding: 0 16px;
       opacity: .9;
    }

    .icon-img {
        display: flex;
        justify-content: center;
        margin: 12px 0;
    }

    .time-d {
        text-align: center;margin-top: 8px;
    }

    .time-d > p{
       margin: 0;
    }

    .pray-dep {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 350px;
            background-image: url('/assets/img/pray_icons/sky.png');
            background-size: cover;
            background-position: center;
        }
        .big-box {
            background-color: white;
            width: 300px;
            height: 180px;
            margin: 10px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgb(0 0 0 / 8%);
            align-items: center;
            display: flex;
        }
        .small-box {
            background-color: white;
            width: 180px;
            height: 180px;
            margin: 10px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgb(0 0 0 / 8%)
        }
        @media (max-width: 600px) {
            .pray-dep {
                flex-direction: column;
                align-items: center;
            }
            .big-box {
                width: 320px;
                margin-bottom: 10px;
            }
            .small-box {
                width: 150px;
                height: 170px;
                margin-bottom: 10px;
            }

            .pray-dep {
                height: 600px; 
            }
        }

        .last-time {
            font-size: 13px;
            color: #858585;
        }

        .pray-name {
            font-weight: bold;
             color: #4f4f4f;
        }

        .time-c {
            color: #4f4f4f;
        }

        .border-m {
            border-left: 2px solid #D9D9D9;
            height: 72px !important;
            padding: 0 12px;
    }

    .time-a {
        text-align: right;
        margin-top: 8px;
        margin-right: 12px;
    }

    .time-a > p { 
        margin: 0;
    }

    .time-a > .pray-name {
        font-size: 20px !important;
    }

    .time-a > .last-time {
        font-size: 14px !important;
    }
</style>